p {
  margin-top: 5px;
  margin-bottom: 5px;
}

.hero-section h2{
  position: relative;
  text-align: center;
  font-size: 24px;
  margin-bottom: 0;
}

.hero-section {
  height: 100vh;
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

div.hero-description {
  width: 100%;
  padding: 0;
}

.bio-item{
  display: flex;
  align-items:center;
  padding: 2.5px 0 2.5px 25px;
}

.bio-icon {
  height: 20px;
  padding-right: 5px;
}

#bioPhoto {
  height: 150px;
  border-radius: 10px;
  display: block;
  margin: 15px auto;
}

#skills{
  font-size: 14px;
  margin-bottom: 0;
}


@media screen and (min-width: 768px){
  .hero-section {
    height: auto;
    width: 100%;
  }

  .hero-section h2 {
    font-size: 40px;
    margin-top: 10px;
  }

  .quick-description {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .quick-description p {
    font-size: 1.5em;
  }

  .bio-item {
    padding-left: 25px;
  }

  .bio-icon {
    height: 30px;
    padding-right: 10px;
  }

  #bioPhoto {
    height: 400px;
    border-radius: 10px;
    margin: 30px auto;
  }

  #skills{
    font-size: 20px;
  }
  
}


@media screen and (min-width: 1200px){
  .hero-section {
    height: 100vh;
    padding-top: 80px;
  }

  div.hero-description {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .bio-icon {
    height: 40px;
    padding-right: 5px;
  }
  
  #bioPhoto {
    margin: 0 25px;
  }
  
  .quick-description {
    padding: 30px 15px 15px 25px;
  }

  .bio-item p {
    padding-left: 5px;
  }
  
 
}

